
html{
  scroll-behavior: smooth;
 
 
}
body{
  margin:0;
  padding:0;
  overflow-x: hidden; 
  box-sizing: border-box;
  width: 100%;
  list-style: none;
  user-select: none;
  text-decoration: none;


  
}



/* .active{
  color: rgb(170, 25, 25);
  ;
} */
.MuiSnackbar-root{
  background-color: #ab1f18 !important;
    color: white;
    font-weight: bold !important;
}
.errorsetstyle2{
  height: 90vh;
  font-size: 3rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-shadow: 1px 2px 3px lightgrey;
  background-color: whitesmoke;
  color:rgb(170, 25, 25);

}

#suspense{
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  width: 100%;
  height: 100vh;
}
@media screen and (min-width:200px) and (max-width:499px) {
  
  .owl-carousel .owl-item img{
    width:250px !important;
    margin: auto;
  }
  
}

@media screen and (min-width:200px) and (max-width:1200px) {
  body{
  
    overflow-x: hidden; 
  }
}
@media screen and (min-width:60px) and (max-width:200px) {
  body{
  
    overflow-x: hidden; 
    width: 100%;
  }
}
@media screen and (min-width:200px) and (max-width:802px) {
  
  .errorsetstyle2{
    font-size: 2rem;
    margin: 60px 8px 0px 8px;
  
  }
}





  




