.video-card{
    padding: 8px;
}
.video-iframe iframe{
  border-radius: 3px 3px 3px 3px;
   
}
.video-cards{
    display: flex;
}
.widget_mc4wp_form_widget .widget-title {
    position: relative;
    display: inline-block;
    width: auto;
    margin-bottom: 30px;
    font-size: 28px;
    font-size: 1.75rem;
    font-weight: 500;
    font-family: "Poppins" , sans-serif;
}
.mc4wp-form .mc4wp-form-fields .fields-container{
    width: 599px;
    margin: auto;
    padding:30px;
  
}

.recipe-button {
    display: inline-block;
    padding: 0 27px;
    background: #ea2b30;
    border-radius: 999px 999px 999px 999px;
    color: #fff;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    line-height: 42px;
    text-align: center;
    transition: .4s;
    font-family: "Poppins" , sans-serif;
    cursor: pointer;
    text-decoration: none;
}
.recipe-button:hover{
  color:black;
  font-weight: 800;
}
.video-footer-logo {
    position: relative;
    
    justify-content: space-between;
    width: 100%;
}
.video-footer-logo {
  position: relative;
  z-index: 9;
  align-items: center;
 
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.video-footer-logo hr {
  width: 100%;
  height: 2px;
  background-color:#EDEDED;
  border-style:none;
  margin: 0;
  opacity: 1;
}
.container-csr .div-p{
  text-align: justify;
  font-size: 18px;
  
  
}
.container-csr li{
  color:#121212;
  list-style-type: square;
  font-size: 17px;
}
.container-csr .div-h {
  font-size: 50px;
  
}
.container-csr .div-h::first-line{
  font-size: 3rem;
  color:#aa1008;
  font-weight: 500;
}


.container-csr{
  /* background-color: rgb(209, 238, 249); */
  padding: 20px 0px;
  font-family: "Poppins" , sans-serif;
  
}
.container-csr .img{
  filter: drop-shadow(3px 3px 5px rgb(31, 29, 29));
}
.csr-flex{
  display: flex;
  justify-content: space-between;
  flex-flow: wrap row;
  text-align: left;
}
.csr-flex  .career_img{
  width: 380px;
  height: 350px;
  filter: drop-shadow(3px 3px 5px rgb(31, 29, 29));
  margin: 0px 10px 40px 10px;
}

.container-csr .csr-glimpse{
  word-spacing: 2px;
  letter-spacing: 0.08rem;
  color:#aa1008;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  
}
.pushphr{
  width:450px;
  margin: auto;
}

@media screen and (min-width:1050px) and (max-width:1150px) {
  .video-card > .video-iframe iframe{
    width:320px;
  }
  }
  @media screen and (min-width:992px) and (max-width:1050px) {
    .video-card > .video-iframe iframe{
      width:300px;
    }
    
    }
    @media screen and (min-width:768px) and (max-width:991px) {
        .video-card > .video-iframe iframe{
          width:375px;
        }
        }
@media screen and (min-width:600px) and (max-width:767px) {
            .video-card > .video-iframe iframe{
              width:550px;
            }
}
@media screen and (min-width:480px) and (max-width:600px) {
    .video-card > .video-iframe iframe{
      width:450px;
    }
}
@media screen and (min-width:370px) and (max-width:479px) {
    .video-card > .video-iframe iframe{
      width:350px;
    }
}
@media screen and (min-width:300px) and (max-width:370px) {
    
    
    .video-card > .video-iframe iframe{
      width:290px;
      object-fit: contain;
    }
    
}
@media screen and (min-width:200px) and (max-width:650px) {
    
  .mc4wp-form .mc4wp-form-fields .fields-container {
    width: 200px;
    margin: auto;
    padding: 30px;
}  
.container-csr .div-h  {
    padding: 8px 10px 0px 10px;
    font-size: 42px;

}
.container-csr .div-p{
  padding: 0px 10px;
  hyphens: auto;
}
.container-csr .div-h::first-line{
  font-size: 42px;
  
}
.container-csr .row img{
  width: 400px;
  margin: 0px auto;
}
.container-csr .img{
  text-align: center;
}
.pushphr{
  width:310px;

 }

 
  
}
@media screen and (min-width:200px) and (max-width:769px) {
  .csr-flex  .career_img{
    width:100%;
    height: 100%;
    filter: drop-shadow(3px 3px 5px rgb(31, 29, 29));
    margin: 0px 10px 40px 10px;
  }
  }
  @media screen and (min-width:769px) and (max-width:992px) {
    .csr-flex .career_img {
      width: 305px;
      height: 300px;
      filter: drop-shadow(3px 3px 5px rgb(31, 29, 29));
      margin: 0px 15px 40px 15px;
      
    }
  }
 
@media screen and (min-width:992px) and (max-width:1300px) {
  .csr-flex .career_img {
    width: 330px;
    height: 310px;
    filter: drop-shadow(3px 3px 5px rgb(31, 29, 29));
    margin: 0px 15px 40px 15px;
    
  }
}