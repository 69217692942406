
@import url('https://fonts.googleapis.com/css2?family=Ibarra+Real+Nova&display=swap');
/* font-family: 'Ibarra Real Nova', serif;
font-family: 'Playfair Display', serif;
font-family: 'Roboto', sans-serif; */
.habout{
    font-family: "Poppins", sans-serif;
    font-weight: bolder;
    letter-spacing: 0.05rem;
    text-align: initial;
}
.habout:first-letter{
    font-size: 3rem;
    color:#aa1008;
    font-weight: 500;
}
 .main-about{
  text-align: justify;
  hyphens:auto;
 } 

      
.founder-div{
  width: 320px;

  margin: 30px auto 0px auto;
}     
.content1{
  color:black;
  padding: 15px 0px;
  font-size: 17px;
  font-family: "Poppins", sans-serif;

  
}
.content2{
  color: #0404049f;
  font-size: 17px;
  padding: 15px 0px;
  font-family: "Poppins", sans-serif;
}
.content2 p{
  color:black;
}
.img-fluid{
  object-fit: contain;
}
.main-about .infra{
  margin-top: 40px;
}
.main-about .infra3{
  margin-top: 120px;
}
.three-about-img{
  height: 220px !important;
}


@media screen and (min-width:200px) and (max-width:767px){
  .main-about .infra{
    margin-top: 0px;
  }
  .main-about .infra3{
    margin-top: 0px;
  }
  .three-about-img{
    height: none;
  }
  
}


